@mixin media-breakpoint-down($point) {

    @if $point==xs {
        @media screen and (max-width: $xs) {
            @content;
        }
    }

    @else if $point==sm {
        @media screen and (max-width: $sm) {
            @content;
        }
    }

    // @else if $point==md {
    //     @media screen and (max-width: $md) {
    //         @content;
    //     }
    // }

    @else if $point==lg {
        @media screen and (max-width: $lg) {
            @content;
        }
    }

    // 20211111 optimizeLayout Alban
    @else if $point==xl {
        @media screen and (max-width: 1440px) {
            @content;
        }
    }

    @else {
        @media screen and (max-width: $point) {
            @content;
        }

    }

}

/**
 * @param {number} $line - 顯示 n 行， 超過文字以...取代
 */
@mixin ellipsis-text($line: 1) {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
}

/**
 *  隱藏
 */
@mixin visually-hidden() {
    border: 0;
    clip-path: polygon(0 0 0 0);
    -webkit-clip-path: polygon(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
}
