.s1wrap {
  max-width: 1000px;
  padding: 55px 5vw;
  margin: auto;
  @media (max-width: 900px) {
    // Material-UI's breakpoint for 'xs'
    padding: 5vh 5vw;
  }
  @media (max-width: 600px) {
    // Material-UI's breakpoint for 'xs'
    padding: 4vh 5vw;
  }
  .h1 {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 30px;
    .span {
      display: inline-block; // 確保底線只在文字下方
      border-bottom: 3px solid #05736f; // 設定底線顏色和粗細
      padding-bottom: 5px; // 增加文字與底線之間的距離
    }
    @media (max-width: 900px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 4rem;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 3.2rem;
      font-weight: bold;
      margin: 3vh 0;
    }
  }
  .p {
    text-align: justify;
    hyphens: auto;
    line-height: 10px !important;
    font-size: 1.8rem;
    line-height: 1.8 !important;
    letter-spacing: 0.02em;
    @media (max-width: 900px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 1.6rem;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 1.4rem;
    }
  }
  .termSection {
    margin-top: 20px;
    margin-bottom: 20px;
    .h2 {
      text-align: left;
      line-height: 10px !important;
      font-weight: bolder !important;
      font-size: 2.2rem;
      font-weight: 500;
      line-height: 3 !important;
      letter-spacing: 0.02em;
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 2rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.8rem;
      }
    }
    .abstract {
      margin-bottom: 10px;

      .p {
        text-align: justify;
        hyphens: auto;
        line-height: 10px !important;
        font-size: 1.8rem;
        line-height: 1.8 !important;
        letter-spacing: 0.02em;
        margin-bottom: 10px;
        @media (max-width: 900px) {
          // Material-UI's breakpoint for 'xs'
          font-size: 1.6rem;
        }
        @media (max-width: 600px) {
          // Material-UI's breakpoint for 'xs'
          font-size: 1.4rem;
        }
      }
    }
    .article {
      list-style-type: decimal; /* 确保有序列表的标号显示 */
      padding-left: 20px;
      line-height: 10px !important;
      font-size: 1.8rem;
      line-height: 1.8 !important;
      letter-spacing: 0.02em;
      margin-bottom: 10px;
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.6rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.4rem;
      }
    }

    .article li {
      margin-bottom: 5px;
      .p {
        text-align: justify;
        hyphens: auto;
        line-height: 10px !important;
        font-size: 1.8rem;
        line-height: 1.8 !important;
        letter-spacing: 0.02em;
        margin-bottom: 10px;
        @media (max-width: 900px) {
          // Material-UI's breakpoint for 'xs'
          font-size: 1.6rem;
        }
        @media (max-width: 600px) {
          // Material-UI's breakpoint for 'xs'
          font-size: 1.4rem;
        }
      }
    }
  }
}
