@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes changeColor {
  0% {
    color: #1ea19c;
  }
  100% {
    color: gray;
  }
}

.speechBubbleCard {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: white;
  background-color: transparent;
  font-size: 1.2em;
  text-align: center;
  overflow: hidden;
}

.backgroundImage {
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.backgroundImg {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.speechBubble {
  position: absolute;
  top: 15px;
  left: 30px;
  z-index: 3;
}

.speechBubble img {
  width: 75px;
  height: auto;
}

.speechBubbleTextWrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 2px solid black;
  border-radius: 20px;
  z-index: 1;
  margin-top: 50px;
  padding: 10px;
  .speechBubbleText {
    width: 80%;
    color: black;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    font-size: 1.6rem;
    line-height: 1.5;
    padding-left: 20px;
    @media (max-width: 900px) {
      font-size: 1.6rem;
      width: 90%;
    }
    @media (max-width: 600px) {
      font-size: 1.6rem;
      width: 100%;
    }
  }
  .btnWrap {
    width: 20%;
    cursor: pointer;
    .btnIcon {
      //color: #1ea19c;
      font-size: 40px;
      text-align: center;
      animation: pulse 1.5s infinite;
    }
    .btnIconAfter {
      color: grey;
      font-size: 40px;
      text-align: center;
      animation: pulse 1.5s infinite;
    }
  }
}
