.wrap {
  display: flex; /* 使用 flexbox */
  align-items: center; /* 垂直置中 */
  justify-content: flex-start; /* 水平靠左對齊 */
  width: 100%; /* 容器全寬 */
  max-width: 1000px;
  .styledText {
    display: inline-block; // 讓底線僅限於文字的寬度
    border-bottom: 2px solid grey; // 添加底線
    align-items: flex-end; // 對齊底部
    text-align: left;
    font-size: 2.2rem; // 基礎字體大小
    .itemNumber {
      font-size: 250% !important; // 放大 item number
      color: grey !important;
      margin-right: 1vw;
    }
    .itemName {
      color: #05736f;
      flex: 1;
    }
  }
}
.gridcontainer {
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: white; /* 白色背景 */
  border-radius: 20px; /* 圓角邊框 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* 陰影效果 */
  padding: 0vh 3vw; /* 內距 */
  margin: 3vh auto;
  margin-bottom: 5vh;
  max-width: inherit;
  min-height: 200px;
  .grid {
    .box {
      display: flex;
      align-items: center;
      gap: 3vw;
      .img {
        width: 30% !important;
        @media (max-width: 900px) {
          // Material-UI's breakpoint for 'xs'
          width: 30% !important;
        }
        @media (max-width: 600px) {
          // Material-UI's breakpoint for 'xs'
          width: 35%;
        }
      }
      .text {
        font-size: 1.8rem !important;
        margin: 3vh 0;
        text-align: justify;
        hyphens: auto;
        word-break: break-word;
        @media (max-width: 900px) {
          // Material-UI's breakpoint for 'xs'
          font-size: 1.4rem !important;
        }
        @media (max-width: 600px) {
          // Material-UI's breakpoint for 'xs'
          font-size: 1.4rem !important;
          margin: 7vh 0;
        }
      }
    }
  }
}
