.root {
  padding: 0;
  color: #fff;
  background-color: #1ea09c;
  width: 100%;
  max-width: none !important;
  padding-bottom: 2vh;
  .m_gridbtncontainer {
    padding-top: 4vh;
    padding-bottom: 1vh;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .m_gridbtn {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 2vh; // 在按鈕之間添加 20px 的間距
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      .m_btn {
        font-size: 1.5rem;
        background-color: white;
        color: #1ea09c;
        width: 90%;
        height: 6vh;
      }
    }
  }
  .m_divider {
    border-width: 1.6px;
    border-color: #fff;
    margin-top: 3vh;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 3vh;
  }
  .gridcontainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3vh;
    @media (max-width: 900px) {
      // Material-UI's breakpoint for 'xs'
      padding-top: 2vh;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      padding-top: 1vh;
      gap: 2vh !important;
    }
    .gridinfo {
      padding-left: 2vw;
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        padding-left: 6vw;
      }
      .title {
        font-size: 2rem;
        font-weight: bold;
        text-align: left;
        @media (max-width: 900px) {
          font-size: 1.6rem;
        }
        @media (max-width: 600px) {
          font-size: 1.6rem;
        }
      }

      .link {
        text-align: left;
        .content {
          font-size: 1.4rem;
          @media (max-width: 900px) {
            font-size: 1.2rem;
          }
          @media (max-width: 600px) {
            font-size: 1.2rem;
          }
        }
      }
      .gridbtncontainer {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (max-width: 600px) {
          display: none;
        }
        .gridbtn {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 2vh;
          align-items: center;
          justify-content: center;
          margin-left: auto;
          margin-right: auto;
          @media (max-width: 600px) {
            display: none;
          }
          .btn {
            background-color: white;
            color: #1ea09c;
            width: 90%;
            height: 4vh;
            padding: 20px 0px 20px 0px;
            transition: background-color 0.3s ease;
            font-size: 1.6rem;
            @media (max-width: 900px) {
              font-size: 1.4rem;
            }
            @media (max-width: 600px) {
              font-size: 1.4rem;
            }
            &:hover {
              background-color: black;
              color: white;
              border-color: white;
            }
          }
        }
      }
    }
  }
  .divider {
    border-width: 1.6px;
    border-color: #fff;
    margin-top: 3vh;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 1vh;
  }
  .copyright {
    font-size: 1.2rem;
  }
}
