.root {
  height: 56vh;
  width: 100%;
  max-width: none !important;
  padding: 0px !important;
  min-height: 480px;
  max-height: 720px;
  background-size: cover;
  background-repeat: no-repeat !important; /* Do not repeat the image */
  background-position: center;
  @media (max-width: 900px) {
    height: 45vh;
    min-height: 400px;
  }
  @media (max-width: 600px) {
    // Material-UI's breakpoint for 'xs'
    height: 45vh;
  }
  .content {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 0 5vw;
    height: 100%;
    width: 100%; /* Example width */

    .item {
      width: 100%;
      max-width: 960px;
      .title {
        font-size: 5rem !important; // Larger size for desktop
        font-weight: 800;
        margin: 40px auto;
        @media (max-width: 900px) {
          // Material-UI's breakpoint for 'md'
          font-size: 4rem; // Slightly smaller for tablets
        }
        @media (max-width: 600px) {
          // Material-UI's breakpoint for 'xs'
          font-size: 2.5rem !important; // Even smaller for mobile phones
          margin-bottom: 3vh;
        }
      }

      .subtitle {
        font-size: 2.4rem !important; // Adequate size for desktop
        margin-bottom: 2.4vw;
        @media (max-width: 900px) {
          font-size: 2rem !important; // Adjust for tablets
        }
        @media (max-width: 600px) {
          font-size: 1.6rem !important; // Smaller for mobile phone.
          margin-bottom: 0;
        }
      }

      .button {
        font-size: 2rem * 0.8;
        background-color: #1ea09c;
        border-radius: 40px;
        padding: 20px 30px !important;
        border-color: #1ea09c !important;
        color: white;
        margin-top: 3vh;
        width: 30%;
        height: 3vh;
        transition: background-color 0.3s ease;
        align-self: center;
        @media (max-width: 900px) {
          // Material-UI's breakpoint for 'xs'
          width: 45%;
          font-size: 1.8rem * 0.8;
        }
        @media (max-width: 600px) {
          width: 60%;
          // Material-UI's breakpoint for 'xs'
          font-size: 1.8rem * 0.8 !important;
        }
        &:hover {
          background-color: white;
          color: #1ea09c;
        }
      }
    }
  }
}
