/* LngToggle.css */
.languageToggle.active {
  font-size: 14px !important;
  font-weight: 700;
  color: #1ea09c;
  cursor: pointer;
}

.languageToggle {
  color: gray;
  font-size: 14px !important;
  font-weight: 100;
  cursor: pointer;
}

.languageSeparator {
  color: #1ea09c;
  font-weight: 800;
  margin-left: 3px !important;
  margin-right: 3px !important;
  cursor: none;
}
