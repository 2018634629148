.s1wrap {
  max-width: 800px;
  padding: 55px 5vw;
  margin: auto;
  @media (max-width: 900px) {
    // Material-UI's breakpoint for 'xs'
    padding: 5vh 5vw;
  }
  @media (max-width: 600px) {
    // Material-UI's breakpoint for 'xs'
    padding: 4vh 5vw;
  }
  .h1 {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 30px;
    .span {
      display: inline-block; // 確保底線只在文字下方
      border-bottom: 3px solid #05736f; // 設定底線顏色和粗細
      padding-bottom: 5px; // 增加文字與底線之間的距離
    }
    @media (max-width: 900px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 4rem;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 3.2rem;
      font-weight: bold;
      margin: 3vh 0;
    }
  }
  .p {
    line-height: 10px !important;
    font-size: 2rem;
    line-height: 1.5 !important;
    letter-spacing: 0.02em;
    @media (max-width: 900px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 1.6rem;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 1.4rem;
    }
  }
}

.s2wrap {
  padding: 5vh 3vw;
  width: 100%;
  max-width: none;
  background-color: #eef1f1;
  .container {
    background-color: transparent;
    .gridcontainer {
      background-color: #1ea09c;
      border-radius: 10px;
      .griditem {
        padding: 50px 20px 30px 20px;
        .h4 {
          font-size: 2.4rem;
          margin-bottom: 10px;
          font-weight: 500;
          color: white;
          .span {
            display: inline-block;
            border-bottom: 2px solid #fff;
            padding-bottom: 2px;
          }
        }
        .p {
          line-height: 10px !important;
          font-size: 1.8rem;
          line-height: 1.5 !important; // 增加行高以改善閱讀性
          letter-spacing: 0.02em; // 增加字母間距
          color: #fff;
          font-style: italic;
          @media (max-width: 900px) {
            // Material-UI's breakpoint for 'xs'
            font-size: 1.6rem;
          }
          @media (max-width: 600px) {
            // Material-UI's breakpoint for 'xs'
            font-size: 1.6rem;
          }
        }
        .info {
          margin-top: 5vh;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          flex-direction: column;
        }
      }
      .gridtable {
        .papertable {
          padding-top: 40px !important;
          display: flex;
          align-items: center;
          height: 100%;
          padding: 20px;
          border-radius: 10px;
          .submitbtn {
            border-radius: 10px;
            padding: 1vh 2vw;
            font-size: 1.2rem;
            color: white;
            background-color: black;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            transition: background-color 0.3s, color 0.3s;
            margin: 20px auto;
            width: 80%;
            max-width: 300px;
            &:hover {
              background-color: #05736f;
              color: white;
            }
          }
        }
      }
    }
  }
}
