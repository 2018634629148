.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    gap: 2;
    width: 100%;
    padding: 20px;
    max-width: 800px;
    user-select: none;
    .img {
      width: 80%;
    }
    .title {
      font-size: 4rem;
      font-weight: bold;
      color: #043258;
      margin-bottom: 30px;

      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 4rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 3rem;
        font-weight: bold;
        margin: 3vh 0;
      }
    }
    .subtitle {
      line-height: 10px !important;
      font-size: 1.6rem;
      line-height: 1.5 !important; // 增加行高以改善閱讀性
      letter-spacing: 0.02em; // 增加字母間距
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.4rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.2rem;
      }
    }
    .btn {
      border-radius: 10px;
      padding: 1vh 7vw;
      font-size: 1.2rem;
      color: white;
      background-color: #1ea09c;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      transition: background-color 0.3s, color 0.3s;
      width: 80%;
      max-width: 300px;
      margin: 5vh auto;
      &:hover {
        background-color: white;
        color: #1ea09c;
      }
    }
  }
}
