.s1wrap {
  position: relative;
  margin: 2vh 0px 2vh 0px;
  width: 100%;
  max-height: 720px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 900px) {
    max-height: none;
  }
  @media (max-width: 600px) {
    // Material-UI's breakpoint for 'xs'
    max-height: none;
  }
  .s1container {
    width: 90%;
    background-color: transparent !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    @media (max-width: 900px) {
      width: 90%;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      width: 96%;
    }
  }
}
.s2wrap {
  width: 100%;
  background-color: #e5f5f5;
  .s2container {
    position: relative;
    max-width: 880px;
    padding: 5vh 30px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: 900px) {
      // Material-UI's breakpoint for 'xs'
      padding: 2vh 5vw;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      padding: 1vh 5vw;
    }
    .h1 {
      font-size: 4rem;
      font-weight: bold;
      margin-bottom: 30px;
      color: #05736f;
      .span {
        display: inline-block; // 確保底線只在文字下方
        border-bottom: 3px solid #05736f; // 設定底線顏色和粗細
        padding-bottom: 5px; // 增加文字與底線之間的距離
      }
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 4rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 3.2rem;
        font-weight: bold;
        margin: 3vh 0;
      }
    }
    .p {
      line-height: 10px !important;
      font-size: 2rem;
      line-height: 1.5 !important;
      letter-spacing: 0.02em;
      text-align: justify;
      hyphens: auto;
      word-wrap: break-word;
      hyphens: auto;
      padding: 1vh 0;
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        padding: 1vh 4vw;
        font-size: 1.6rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        padding: 2vh 0;
        font-size: 1.6rem;
      }
    }
  }
}

.s3wrap {
  position: relative;
  max-width: 880px;
  padding: 2vh 30px;
  margin: auto;
  margin-bottom: 40px;
  @media (max-width: 900px) {
    // Material-UI's breakpoint for 'xs'
    padding: 2vh 5vw;
  }
  @media (max-width: 600px) {
    // Material-UI's breakpoint for 'xs'
    padding: 1vh 5vw;
  }
  .h1 {
    color: #05736f;
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 30px;
    .span {
      display: inline-block; // 確保底線只在文字下方
      border-bottom: 3px solid #05736f; // 設定底線顏色和粗細
      padding-bottom: 5px; // 增加文字與底線之間的距離
    }
    @media (max-width: 900px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 4rem;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 3.2rem;
      font-weight: bold;
      margin: 3vh 0;
    }
  }
  .h2 {
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 1.5 !important;
    @media (max-width: 900px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 2.2rem;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 2rem;
      font-weight: bold;
      margin: 3vh 0;
    }
  }
  .h3 {
    color: #05736f;
    font-size: 2.2rem;
    font-weight: bold;
    margin: 40px auto 5px auto;
    @media (max-width: 900px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 2rem;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 2rem;
      font-weight: bold;
      margin: 3vh 0;
    }
  }
  .time {
    color: grey;
    font-size: 1.8rem;
    font-style: italic;
    font-weight: bold;
    @media (max-width: 900px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 1.6rem;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 1.6rem;
      font-weight: bold;
      margin: 3vh 0;
    }
  }
  .divider {
    width: 20%;
    margin: 10px auto 20px auto;
    border-width: 1.5px;
    border-color: #05736f;
  }
  .p {
    line-height: 10px !important;
    font-size: 2rem;
    line-height: 1.5 !important;
    letter-spacing: 0.02em;
    text-align: justify;
    hyphens: auto;
    word-wrap: break-word;
    padding: 1.5vh 0;
    @media (max-width: 900px) {
      // Material-UI's breakpoint for 'xs'
      padding: 1vh 4vw;
      font-size: 1.6rem;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      padding: 1vh 0;
      font-size: 1.6rem;
    }
  }
}

.s6wrap {
  width: 100%;
  background-color: #e5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5vh 0vw 5vh 0vw;
  @media (max-width: 900px) {
    // Material-UI's breakpoint for 'xs'
    padding: 4vh 2vw 4vh 2vw !important;
  }
  @media (max-width: 600px) {
    // Material-UI's breakpoint for 'xs'
    padding: 3vh 2vw 3vh 2vw !important;
  }
  .s6container {
    width: 90%;
    background-color: inherit;
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-width: 800px;

    @media (max-width: 900px) {
      width: 90%;
      overflow-y: hidden !important;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      width: 96%;
    }
    .h1 {
      font-size: 2.4rem;
      font-weight: bold;
      margin-bottom: 40px;
      color: black;
      .span {
        display: inline-block; // 確保底線只在文字下方
        border-bottom: 2px solid #05736f; // 設定底線顏色和粗細
        padding-bottom: 5px;
      }
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 2.4rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 2rem;
        font-weight: bold;
        margin: 3vh 0;
      }
    }
    .faqcontainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      gap: 2vh;
    }
  }
}
