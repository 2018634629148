.s2wrap {
  width: 100%;
  background-color: #e5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .s2container {
    width: 90%;
    background-color: inherit;
    padding: 6vh 0px 5vh 0px !important;
    max-width: 1200px;
    @media (max-width: 900px) {
      width: 90%;
      overflow-y: hidden !important;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      width: 100%;
      padding: 3vh 5vw 3vh 5vw !important;
    }
    .h1 {
      font-size: 3rem * 0.8;
      font-weight: bold;
      margin-bottom: 1vh;
      text-align: left;
      .span {
        display: inline-block;
        border-bottom: 3px solid #4074b0;
        padding-bottom: 5px;
      }
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 2.4rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 2rem;
        font-weight: bold;
        margin: 1vh 0;
      }
    }
    .p {
      text-align: justify;
      line-height: 10px !important;
      font-size: 2rem * 0.8;
      margin: 20px auto;
      line-height: 1.5 !important; // 增加行高以改善閱讀性
      letter-spacing: 0.02em; // 增加字母間距
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.8rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.6rem;
      }
    }
    .divider {
      border-color: transparent;
      margin: 1vh 0px 1vh 0px;
    }
    .boxcontainer {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: inherit;
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        flex-direction: column;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        flex-direction: column;
      }
      .boxImg {
        width: 40%;
        background-color: inherit;
        @media (max-width: 900px) {
          // Material-UI's breakpoint for 'xs'
          width: 100%;
        }
        @media (max-width: 600px) {
          // Material-UI's breakpoint for 'xs'
          width: 100%;
        }
      }
      .boxInfo {
        width: 60%;
        background-color: inherit;
        padding: 3vh 5vw 3vh 5vw !important;
        @media (max-width: 900px) {
          // Material-UI's breakpoint for 'xs'
          padding: 3vh 2vw 3vh 2vw !important;
          width: 100%;
        }
        @media (max-width: 600px) {
          // Material-UI's breakpoint for 'xs'
          padding: 3vh 2vw 3vh 2vw !important;
          width: 100%;
        }
        .list {
          .item {
            padding: 1vh 0 1vh 0;
            .text {
              text-align: justify;
              font-size: 1.6rem !important;
              margin-left: 0.5vw;
              letter-spacing: 0.02em; // 增加字母間距
              @media (max-width: 900px) {
                // Material-UI's breakpoint for 'xs'
                font-size: 1.6rem !important;
                margin-left: 1vw;
              }
              @media (max-width: 600px) {
                // Material-UI's breakpoint for 'xs'
                font-size: 1.4rem !important;
                margin-left: 2vw;
              }
            }
          }
          .h3 {
            font-size: 2.4rem;
            font-weight: bold;
            margin-bottom: 1vh;
            text-align: left;
            .span {
              display: inline-block; // 確保底線只在文字下方
              border-bottom: 2px solid #4074b0;
            }
            @media (max-width: 900px) {
              // Material-UI's breakpoint for 'xs'
              font-size: 2.4rem;
            }
            @media (max-width: 600px) {
              // Material-UI's breakpoint for 'xs'
              font-size: 1.8rem;
              font-weight: bold;
              margin: 1vh 0;
            }
          }
        }
      }
    }
  }
  .summary {
    align-self: center;
    justify-self: center;
    width: 100%;
    padding: 0 12vw 0 12vw;
    @media (max-width: 900px) {
      // Material-UI's breakpoint for 'xs'
      padding: 0vh 2vw 0vh 2vw !important;
      padding: 0px;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      padding: 0vh 2vw 0vh 2vw !important;
      padding: 0px;
    }
    .h2 {
      font-size: 2.4rem;
      font-weight: bold;
      margin-bottom: 3vh;
      text-align: center;
      .span {
        display: inline-block; // 確保底線只在文字下方
        border-bottom: 2px solid #4074b0;
      }
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 2.4rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.8rem;
        font-weight: bold;
        margin: 1vh 0;
      }
    }
    .list {
      margin: 0 !important;
      padding: 0 !important;
      .h4 {
        color: rgb(71, 67, 67);
        font-size: 1.8rem;
        margin: 1vh auto 0vh auto !important;
        text-align: center;
        font-style: italic;
        font-weight: bold;
        @media (max-width: 900px) {
          // Material-UI's breakpoint for 'xs'
          font-size: 1.8rem;
        }
        @media (max-width: 600px) {
          // Material-UI's breakpoint for 'xs'
          font-size: 1.6rem;
          font-weight: bold;
          margin: 3vh 0 0 0 !important;
        }
      }
      .item {
        padding: 1vh 0 1vh 0;
        .text {
          text-align: left;
          font-size: 1.6rem !important;
          margin-left: 0.5vw;
          line-height: 1.5 !important;
          letter-spacing: 0.02em; // 增加字母間距
          @media (max-width: 900px) {
            // Material-UI's breakpoint for 'xs'
            font-size: 1.6rem !important;
            margin-left: 1vw;
          }
          @media (max-width: 600px) {
            // Material-UI's breakpoint for 'xs'
            font-size: 1.4rem !important;
            margin-left: 2vw;
          }
        }
      }
    }
  }
}
