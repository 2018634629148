.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #05736f;
  border-radius: 20px !important;
  width: 100%;
  max-width: 600px;
  margin: 20px 10px;
  padding: 20px 10px;
  .avatarWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 29%;
    .avatar {
      width: 70px;
      height: 70px;
    }
  }
  .dividerWrap {
    width: 2%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .infoWrap {
    width: 69%;
    padding-left: 5%;
    .h3 {
      text-align: left;
      font-size: 1.8rem;
      color: #05736f;
      font-weight: bold;
    }
    .h6 {
      text-align: left;
      font-size: 1.4rem;
      font-style: italic;
      margin: 5px auto;
    }
    .p {
      margin: 5px auto;
      text-align: justify;
      hyphens: auto;
      word-break: break-all;
      font-size: 1.4rem;
      padding-right: 20px;
      color: grey;
      line-height: 1.5 !important;
      letter-spacing: 0.02em;
    }
  }
}
