.wrap {
  width: 100%;
  .accordion {
    .title {
      padding: 0vh 2vw 0vh 2vw;
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        padding: 0vh 3vw 0vh 3vw;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        padding: 0vh 5vw 0vh 5vw;
      }
      .qawrap {
        display: flex;
        align-items: center;
        justify-self: flex-start;
        .symb {
          font-size: 1.8rem;
          margin: 0vh 1vw 0vh 0vw;
          font-weight: bolder;
          @media (max-width: 900px) {
            // Material-UI's breakpoint for 'xs'
            font-size: 1.6rem;
          }
          @media (max-width: 600px) {
            // Material-UI's breakpoint for 'xs'
            margin: 0vh 2vw 0vh 0vw;
            font-size: 1.4rem;
          }
        }
        .info {
          font-size: 1.6rem;
          text-align: justify;
          hyphens: auto;
          word-break: break-all;
          line-height: 1.5 !important; // 增加行高以改善閱讀性
          @media (max-width: 900px) {
            // Material-UI's breakpoint for 'xs'
            font-size: 1.6rem;
          }
          @media (max-width: 600px) {
            // Material-UI's breakpoint for 'xs'
            font-size: 1.4rem;
            margin: 0vh 0vw 0vh 0vw;
          }
        }
      }
    }
    .details {
      background-color: #e9f2f2;
      padding: 3vh 2vw 3vh 2vw;
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        padding: 2vh 3vw 2vh 3vw;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        padding: 3vh 5vw 3vh 5vw;
      }
      .qawrap {
        display: flex;
        align-items: center;
        justify-self: flex-start;
        .symb {
          font-size: 1.8rem;
          margin: 0vh 1vw 0vh 0vw;
          font-weight: bolder;
          @media (max-width: 900px) {
            // Material-UI's breakpoint for 'xs'
            font-size: 1.6rem;
          }
          @media (max-width: 600px) {
            // Material-UI's breakpoint for 'xs'
            margin: 0vh 2vw 0vh 0vw;
            font-size: 1.4rem;
          }
        }
        .info {
          content: "";
          .p {
            font-size: 1.6rem;
            text-align: justify;
            hyphens: auto;
            word-wrap: break-word;
            line-height: 1.5 !important; // 增加行高以改善閱讀性
            @media (max-width: 900px) {
              // Material-UI's breakpoint for 'xs'
              font-size: 1.6rem;
            }
            @media (max-width: 600px) {
              // Material-UI's breakpoint for 'xs'
              font-size: 1.4rem;
              margin: 0vh 0vw 0vh 0vw;
            }
          }
          .items {
            list-style-type: decimal;
            padding-left: 20px;
            font-size: 1.8rem;
            text-align: justify;
            hyphens: auto;
            word-wrap: break-word;
            line-height: 1.5 !important;
            @media (max-width: 900px) {
              // Material-UI's breakpoint for 'xs'
              font-size: 1.6rem;
            }
            @media (max-width: 600px) {
              // Material-UI's breakpoint for 'xs'
              font-size: 1.4rem;
              margin: 0vh 0vw 0vh 0vw;
            }
          }

          .items li {
            margin-top: 5px;
            margin-bottom: 5px;
            .p {
              font-size: 1.8;
              text-align: justify;
              hyphens: auto;
              word-wrap: break-word;
              line-height: 1.5 !important;
              @media (max-width: 900px) {
                // Material-UI's breakpoint for 'xs'
                font-size: 1.6rem;
              }
              @media (max-width: 600px) {
                // Material-UI's breakpoint for 'xs'
                font-size: 1.4rem;
                margin: 0vh 0vw 0vh 0vw;
              }
            }
          }
        }
      }
    }
  }
}
