%wrap {
  width: 100%;
}

%row {
  max-width: $xl;
  margin: auto;
}

%alinkBtn {
  text-decoration: none;

  // &:active {
  //     background-color: $white;
  // }
}

%h2 {
  font-size: 2.8rem;
  line-height: 1.21;
  color: $black;
  padding-top: 4rem;
  padding-bottom: 2.4rem;

  @include media-breakpoint-down(sm) {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
}

%cf {
  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

%button {
  transition: opacity 0.3s cubic-bezier(0.18, 0.85, 0.5, 1.2);

  &:hover {
    opacity: 0.75;
  }
}

%light-button {
  transition: all 0.3s cubic-bezier(0.18, 0.85, 0.5, 1.2);

  &:hover {
    color: $white;
    background-color: #39a3fd;
  }
}

%button-disabled {
  background-color: $gray;
}

%text-link {
  &:hover {
    text-decoration: underline;
  }
}
