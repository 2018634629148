.s1wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  .imgwrap {
    height: auto;
    width: 100%;
    max-width: none !important;
    padding: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 900px) {
      width: 100%;
      height: auto;
    }
    @media (max-width: 600px) {
      width: 100%;
      height: auto;
    }
    .img {
      width: 100%;
      max-width: 1000px;
      height: inherit;
      object-fit: contain;
    }
  }
}

.s2wrap {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 0;
  margin-bottom: 40px;
  .s2container {
    width: 90%;
    background-color: inherit;
    padding: 40px 0px 60px 0px !important;
    max-width: 900px;
    @media (max-width: 900px) {
      width: 90%;
      overflow-y: hidden !important;
      padding: 40px 0px 60px 0px !important;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      width: 100%;
      padding: 40px 5vw 60px 5vw !important;
    }
    .h1 {
      font-size: 7rem * 0.8;
      font-weight: bold;
      margin: 0;
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 6rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 3rem;
        font-weight: bold;
        margin: 1vh 0;
      }
    }
    .p {
      text-align: justify;
      word-wrap: break-word;
      hyphens: auto;
      padding: 0 2%;
      font-size: 2.4rem * 0.8;
      line-height: 1.8 !important;
      letter-spacing: 0.02em;
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        text-align: justify;
        font-size: 2.4rem;
        padding: 0 2%;
      }
      @media (max-width: 600px) {
        text-align: justify;
        // Material-UI's breakpoint for 'xs'
        font-size: 1.6rem;
        padding: 0 2%;
      }
      .a {
        font-size: 1.7rem;
        .sup {
          vertical-align: super;
          font-size: 1.2rem;
        }
      }
    }
  }
  .s4btn {
    margin: 5vh auto;
    border-radius: 40px;
    padding: 1.5vh 7vw;
    font-size: 1.4rem;
    border: 2px solid #4074b0;
    color: #ffffff;
    font-weight: bold;
    background-color: #4074b0;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: #ffffff;
      color: #4074b0;
    }
  }
}

.s4wrap {
  width: 100%;
  background-color: #e5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5vh 0vw 5vh 0vw;
  @media (max-width: 900px) {
    // Material-UI's breakpoint for 'xs'
    padding: 4vh 2vw 4vh 2vw !important;
  }
  @media (max-width: 600px) {
    // Material-UI's breakpoint for 'xs'
    padding: 3vh 2vw 3vh 2vw !important;
  }
  .s4container {
    width: 90%;
    background-color: inherit;
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-width: 1000px;
    @media (max-width: 900px) {
      width: 90%;
      overflow-y: hidden !important;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      width: 96%;
    }
    .h1 {
      font-size: 2.4rem;
      font-weight: bold;
      margin-bottom: 40px;
      color: black;
      .span {
        display: inline-block; // 確保底線只在文字下方
        border-bottom: 2px solid #05736f; // 設定底線顏色和粗細
        padding-bottom: 5px;
      }
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.8rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.5rem;
        font-weight: bold;
        margin: 3vh 0;
      }
    }
    .gridcontainer {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 1100px;
      margin-left: auto;
      margin-right: auto;
    }
    .grid {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1vh;
      margin-bottom: 1vh;
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs
        margin-top: 1vh;
        margin-bottom: 1vh;
      }
    }
  }
}

.s5wrap {
  width: 100%;
  background-color: #1ea09c;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2vh 0vw 2vh 0vw;
  @media (max-width: 900px) {
    // Material-UI's breakpoint for 'xs'
    padding: 4vh 2vw 4vh 2vw !important;
  }
  @media (max-width: 600px) {
    // Material-UI's breakpoint for 'xs'
    padding: 3vh 2vw 3vh 2vw !important;
  }
  .s5container {
    width: 90%;
    background-color: inherit;
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 900px) {
      width: 90%;
      overflow-y: hidden !important;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      flex-direction: column;
      width: 96%;
    }
    .btn {
      border-radius: 10px;
      padding: 10px 2vw;
      font-size: 1.8rem;
      color: #1ea09c;
      background-color: #fff;
      border-color: #fff;
      border-width: 2px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      transition: background-color 0.3s, color 0.3s;
      margin: 10px auto;
      width: 80%;
      max-width: 300px;
      &:hover {
        background-color: black;
        color: white;
      }
    }
    .disabledbtn {
      border-radius: 10px;
      padding: 10px 2vw;
      font-size: 1.6rem;
      color: white;
      border-color: transparent;
      background-color: rgb(183, 176, 176);
      text-align: center;
      text-decoration: none;
      display: inline-block;
      transition: background-color 0.3s, color 0.3s;
      margin: 10px auto;
      width: 80%;
      max-width: 300px;
      &:hover {
        background-color: white;
        color: black;
      }
    }
  }
}

.s6wrap {
  width: 100%;
  background-color: #e5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5vh 0vw 5vh 0vw;
  @media (max-width: 900px) {
    // Material-UI's breakpoint for 'xs'
    padding: 4vh 2vw 4vh 2vw !important;
  }
  @media (max-width: 600px) {
    // Material-UI's breakpoint for 'xs'
    padding: 3vh 2vw 3vh 2vw !important;
  }
  .s6container {
    width: 90%;
    background-color: inherit;
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-width: 800px;

    @media (max-width: 900px) {
      width: 90%;
      overflow-y: hidden !important;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      width: 96%;
    }
    .h1 {
      font-size: 2.4rem;
      font-weight: bold;
      margin-bottom: 40px;
      color: black;
      .span {
        display: inline-block; // 確保底線只在文字下方
        border-bottom: 2px solid #05736f; // 設定底線顏色和粗細
        padding-bottom: 5px;
      }
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 2.4rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 2rem;
        font-weight: bold;
        margin: 3vh 0;
      }
    }
    .faqcontainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      gap: 2vh;
    }
  }
}
