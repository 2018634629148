@import './reset.css';
@import './variable.scss';
@import './mixin.scss';

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: $font-family-sans-serif;
  color: $black;
  min-width: 320px;
}

button {
  &.disabled {
    pointer-events: none;
  }
}

a:focus {
  outline: none;
}

.carousel-root {
  height: 100%;
  .carousel {
    height: 100%;
    .slider-wrapper {
      height: 100%;
    }

    .control-dots {
      bottom: 1.5rem;
      @include media-breakpoint-down(sm) {
        bottom: 0.6rem !important;
      }
      .dot {
        box-shadow: none;
        width: 1rem;
        height: 1rem;
        opacity: 0.7;
        background: transparent;
        border: white 1px solid;
        margin: 0 5px;

        &.selected,
        &:hover {
          // border: 2px solid $primaryBlue;
          // background: $primaryBlue !important;
          background: white;
        }
      }
    }
  }

  .control-arrow {
    // display: none;
    width: 15%;
  }
  .carousel.carousel-slider .control-prev:hover {
    background: linear-gradient(0.25turn, rgba(0, 0, 0, 0.3), transparent);
  }
  .carousel.carousel-slider .control-next:hover {
    background: linear-gradient(0.25turn, transparent, rgba(0, 0, 0, 0.3));
  }
}
