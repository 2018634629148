@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.8;
  }
}

@keyframes fadeInAll {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOutAll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slideIn {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes slideUp {
  0% {
    height: 100%;
    opacity: 1;
  }

  100% {
    height: 0;
    opacity: 0;
    display: none;
  }
}

@keyframes coverZoomInOut {
  0% {
    background-size: 3328px 1872px;
  }

  100% {
    background-size: 2560px 1440px;
  }
}

@keyframes twinkle {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0.3;
  }
}
