.s1wrap {
  max-width: 800px;
  padding: 55px 5vw;
  margin: auto;
  @media (max-width: 900px) {
    // Material-UI's breakpoint for 'xs'
    padding: 5vh 5vw;
  }
  @media (max-width: 600px) {
    // Material-UI's breakpoint for 'xs'
    padding: 4vh 5vw;
  }
  .h1 {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 30px;
    .span {
      display: inline-block; // 確保底線只在文字下方
      border-bottom: 3px solid #05736f; // 設定底線顏色和粗細
      padding-bottom: 5px; // 增加文字與底線之間的距離
    }
    @media (max-width: 900px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 4rem;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 3.2rem;
      font-weight: bold;
      margin: 3vh 0;
    }
  }
  .p {
    line-height: 10px !important;
    font-size: 2rem;
    line-height: 1.5 !important; // 增加行高以改善閱讀性
    letter-spacing: 0.02em; // 增加字母間距
    @media (max-width: 900px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 1.6rem;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 1.4rem;
    }
  }
}

.s2wrap {
  width: 100%;
  background-color: #e5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    margin-top: 6vh;
    margin-bottom: 6vh;
    padding: 20px;
    width: 90%;
    max-width: 800px;
    .form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .textfield {
        content: "";
      }
      .formControl {
        .inputLabel {
          content: "";
        }
        .select {
          .menuItem {
            content: "";
          }
        }
      }
      .p {
        margin: 0.5vw 0px;
        padding-left: 5px;
        text-align: justify;
        align-self: flex-start;
        .sup {
          font-size: 5px;
          vertical-align: super;
        }
      }
      .formControlLabel {
        padding-left: 5px;
        text-align: justify;
        font-size: 24px !important;
        align-self: flex-start;
      }
      .submitbtn {
        border-radius: 10px;
        padding: 1vh 2vw;
        font-size: 1.2rem;
        color: white;
        background-color: black;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        transition: background-color 0.3s, color 0.3s;
        margin: 20px auto;
        width: 80%;
        max-width: 300px;
        &:hover {
          background-color: #05736f;
          color: white;
        }
      }
    }
  }
}
