.speechBubbleCard {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: white;
  background-color: transparent;
  font-size: 1.2em;
  text-align: center;
  overflow: hidden;
}

.backgroundImage {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.backgroundImg {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.speechBubble {
  position: absolute;
  top: 10px;
  left: 10px;
}

.speechBubble img {
  width: 150px;
  height: auto;
}

.speechBubbleText {
  width: 90%;
  position: relative;
  z-index: 1;
  color: #62647f;
  margin-top: 50px;
  padding: 10px;
  font-size: 1.6rem;
  line-height: 1.8;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  @media (max-width: 900px) {
    font-size: 1.6rem;
    width: 90%;
  }
  @media (max-width: 600px) {
    font-size: 1.6rem;
    width: 100%;
  }
}

.fade-enter {
  opacity: 0;
  transform: translateX(100%);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}
.fade-exit {
  opacity: 1;
  transform: translateX(0);
}
.fade-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 500ms, transform 500ms;
}
