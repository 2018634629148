.wrap {
  width: 100%;
  .accordion {
    .title {
      padding-left: 30px;
      font-weight: 500;
    }
    .details {
      background-color: #eef1f1;
      padding: 20px 30px;
      border-radius: 40 !important;
      text-align: justify;
      overflow-wrap: break-word;
      hyphens: auto;
      font-size: 1.6rem;
      line-height: 1.5 !important; // 增加行高以改善閱讀性
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.4rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.2rem;
      }
    }
  }
  .divider {
    margin: 20px auto;
    width: 90%;
    border-color: #1ea09c;
    border-width: 1px;
  }
}
