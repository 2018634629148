.s1wrap {
  height: auto;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .s1container {
    width: 90%;
    background-color: white !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    @media (max-width: 900px) {
      width: 90%;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      width: 96%;
    }
  }
}

.s2wrap {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5vh 0vw 5vh 0vw;
  @media (max-width: 900px) {
    // Material-UI's breakpoint for 'xs'
    padding: 4vh 2vw 4vh 2vw !important;
  }
  @media (max-width: 600px) {
    // Material-UI's breakpoint for 'xs'
    padding: 3vh 2vw 3vh 2vw !important;
  }
  .s2container {
    width: 90%;
    background-color: inherit;
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-width: 900px;
    @media (max-width: 900px) {
      width: 90%;
      overflow-y: hidden !important;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      width: 96%;
    }
    .h1 {
      font-weight: bold;
      margin-bottom: 40px;
      color: black;
      .span {
        font-size: 2.4rem;
        display: inline-block; // 確保底線只在文字下方
        border-bottom: 2.5px solid #4074b0; // 設定底線顏色和粗細
        padding-bottom: 5px;
        @media (max-width: 900px) {
          // Material-UI's breakpoint for 'xs'
          font-size: 2.2rem;
        }
        @media (max-width: 600px) {
          // Material-UI's breakpoint for 'xs'
          font-size: 2rem;
          font-weight: bold;
          margin: 3vh 0;
        }
      }
    }
    .gridcontainer {
      padding: 20px 0px;
    }
  }
}

.s4wrap {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5vh 0vw 5vh 0vw;
  @media (max-width: 900px) {
    // Material-UI's breakpoint for 'xs'
    padding: 4vh 2vw 4vh 2vw !important;
  }
  @media (max-width: 600px) {
    // Material-UI's breakpoint for 'xs'
    padding: 3vh 2vw 3vh 2vw !important;
  }
  .s4container {
    width: 90%;
    background-color: inherit;
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-width: 1200px;
    @media (max-width: 900px) {
      width: 90%;
      overflow-y: hidden !important;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      width: 96%;
    }
    .h1 {
      font-weight: bold;
      margin-bottom: 40px;
      color: black;
      .span {
        display: inline-block; // 確保底線只在文字下方
        font-size: 2.4rem;
        border-bottom: 2.5px solid #4074b0; // 設定底線顏色和粗細
        padding-bottom: 5px;
        @media (max-width: 900px) {
          // Material-UI's breakpoint for 'xs'
          font-size: 2.2rem;
        }
        @media (max-width: 600px) {
          // Material-UI's breakpoint for 'xs'
          font-size: 2rem;
          font-weight: bold;
          margin: 3vh 0;
        }
      }
    }
    .gridcontainer {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 1100px;
      margin-left: auto;
      margin-right: auto;
    }
    .grid {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1vh;
      margin-bottom: 1vh;
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs
        margin-top: 1vh;
        margin-bottom: 1vh;
      }
    }
  }
}

.s5wrap {
  width: 100%;
  background-color: #e3edf5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2vh 0vw 2vh 0vw;
  @media (max-width: 900px) {
    // Material-UI's breakpoint for 'xs'
    padding: 4vh 2vw 4vh 2vw !important;
  }
  @media (max-width: 600px) {
    // Material-UI's breakpoint for 'xs'
    padding: 3vh 2vw 3vh 2vw !important;
  }
  .s5container {
    width: 90%;
    background-color: inherit;
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 900px) {
      width: 90%;
      overflow-y: hidden !important;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      flex-direction: column;
      width: 96%;
    }
    .btn {
      border-radius: 10px;
      padding: 3vh 2vw;
      font-size: 1.8rem;
      color: white;
      background-color: #4074b0;
      border-color: #4074b0;
      border-width: 2px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      transition: background-color 0.3s, color 0.3s;
      margin: 10px auto;
      width: 80%;
      max-width: 300px;
      &:hover {
        background-color: white;
        color: #0a2958;
      }
    }
    .disabledbtn {
      border-radius: 10px;
      padding: 3vh 2vw;
      font-size: 1.2rem;
      color: white;
      border-color: white;
      background-color: rgb(183, 176, 176);
      text-align: center;
      text-decoration: none;
      display: inline-block;
      transition: background-color 0.3s, color 0.3s;
      margin: 10px auto;
      width: 80%;
      max-width: 300px;
      &:hover {
        background-color: white;
        color: black;
      }
    }
  }
}

.s6wrap {
  width: 100%;
  background-color: #e5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5vh 0vw 5vh 0vw;
  @media (max-width: 900px) {
    // Material-UI's breakpoint for 'xs'
    padding: 4vh 2vw 4vh 2vw !important;
  }
  @media (max-width: 600px) {
    // Material-UI's breakpoint for 'xs'
    padding: 3vh 2vw 3vh 2vw !important;
  }
  .s6container {
    width: 90%;
    background-color: inherit;
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-width: 800px;

    @media (max-width: 900px) {
      width: 90%;
      overflow-y: hidden !important;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      width: 96%;
    }
    .h1 {
      font-weight: bold;
      margin-bottom: 40px;
      color: black;
      .span {
        font-size: 2.4rem;
        display: inline-block; // 確保底線只在文字下方
        border-bottom: 2px solid #05736f; // 設定底線顏色和粗細
        padding-bottom: 5px;
      }
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 2.4rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 2rem;
        font-weight: bold;
        margin: 3vh 0;
      }
    }
    .faqcontainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      gap: 2vh;
    }
  }
}
