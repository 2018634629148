.root {
  user-select: none;
  max-width: 290px;
  text-align: center;
  gap: 1vw !important;
  border-radius: 20px !important;
  overflow: hidden;
  @media (max-width: 900px) {
    // Material-UI's breakpoint for 'xs'
    max-width: none;
    width: 90%;
  }
  @media (max-width: 600px) {
    // Material-UI's breakpoint for 'xs'
    max-width: none;
    width: 80%;
  }
  .cardcontent {
    border-radius: 5vw;
    .imgicon {
      height: 100px !important;
      margin-bottom: 10px;
    }
    .title {
      font-size: 1.8rem;
      font-weight: bold;
    }
    .divider {
      border-width: 1.6px;
      margin-top: 1.5vh;
      margin-bottom: 2vh;
    }
    .content {
      text-align: left;
      hyphens: auto;
      word-break: break-word;
      line-height: 1.5;
      padding: 0vh 10px;
      font-size: 1.6rem;
    }
  }
}
