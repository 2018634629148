// NavigationBar.scss
.whiteToolbar {
  width: 100% !important;
  min-width: none !important;
  user-select: none !important;
  z-index: 1300 !important;
  height: 50px !important;
  //height: 5vh !important;
  color: #333;
  background-color: white !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.desktopLinkContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px !important;
  padding-right: 30px !important;
  gap: 30px !important;
}

.no-scroll {
  overflow: hidden;
}

.accordionSummary {
  min-height: unset;
  .MuiAccordionSummary-content {
    padding: 0;
    margin: 0; // 取消内容间距
  }
}

.accordionDetails {
  padding: 0 16px; // 调整内容间距
}
.navLink {
  user-select: none !important;
  text-transform: capitalize !important;
  padding: 0px 10px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: black;
  text-decoration: none;
  position: relative;
  //overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    background-color: white !important;
    transform: translateY(2px);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1.5px;
    background-color: #1ea09c;
    transition: width 0.3s ease;
  }

  &:hover::after {
    width: 100%;
  }
}

.hamburger_react {
  background-color: blue !important;
  color: blue !important;
  /* 可以調整 size 屬性來進一步控制大小 */
}

.centerList {
  display: flex !important;
  justify-content: center !important;
  padding: 0 !important;
}

.contactButton {
  text-transform: capitalize !important;
  padding: 3px 20px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #1ea09c !important;
  background-color: white;
  border: 1.2px solid #1ea09c !important;
  border-radius: 20px !important;
  outline: none;
  cursor: pointer;
  margin-right: 10px !important;
  margin-left: 30px !important;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #1ea09c !important;
    font-weight: 800 !important;
    color: white !important;
  }

  @media (max-width: 1200px) {
    font-size: 16px !important;
    padding: 10px !important;
    width: 90% !important;
    margin: 3vh !important;
    background-color: #fff !important;
    justify-content: center !important;
  }

  @media (max-width: 600px) {
    width: 90% !important;
    margin: 3vh !important;
    background-color: #fff !important;
    font-size: 16px !important;
    justify-content: center !important;
  }
}

.logoLink {
  display: inline-flex;
  align-items: center;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
  }

  &:hover::after {
    opacity: 1;
  }
}

.drawer {
  width: 100% !important;
  background-color: transparent !important;
  color: transparent !important;
  overlay: none !important;
}

.drawerPaper {
  /* top: max(5vh, 40px) !important;
   */
  padding-top: 70px;
  height: 100% !important;
  background-color: #1ea09c !important;
  color: black !important;
  width: 100% !important;
  overflow: hidden !important;
}

.drawerbackdrop {
  background-color: transparent !important;
}

.iconButton {
  transition: transform 0.1s ease-in-out;
}

.iconButton.rotate {
  transform: rotate(180deg);
}
.drawerContainer {
  padding: 10px 40px;
}

.drawerItems {
  font-size: 18px !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.drawerList {
  margin-top: 3vh !important;
  margin-bottom: 1vh !important;
}

@media (max-width: 960px) {
  .languageBtn {
    margin-right: 3vw !important;
  }
}

@media (max-width: 600px) {
  .languageBtn {
    margin-right: 6vw !important;
  }
}
