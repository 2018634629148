.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    gap: 2;
    width: 100%;
    padding: 20px;
    max-width: 800px;
    user-select: none;
    .img {
      width: 240px;
      height: 240px;
    }
    .title {
      font-size: 4rem;
      font-weight: bold;
      margin-bottom: 30px;
      .span {
        display: inline-block; // 確保底線只在文字下方
        border-bottom: 3px solid #05736f; // 設定底線顏色和粗細
        padding-bottom: 5px; // 增加文字與底線之間的距離
      }
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 4rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 3rem;
        font-weight: bold;
        margin: 3vh 0;
      }
    }
    .subtitle {
      line-height: 10px !important;
      font-size: 1.6rem;
      line-height: 1.5 !important; // 增加行高以改善閱讀性
      letter-spacing: 0.02em; // 增加字母間距
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.4rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.2rem;
      }
    }
    .btn {
      border-radius: 10px;
      padding: 1vh 7vw;
      font-size: 1.2rem;
      color: white;
      background-color: black;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      transition: background-color 0.3s, color 0.3s;
      width: 80%;
      max-width: 300px;
      margin: 5vh auto;
      &:hover {
        background-color: #05736f;
        color: white;
      }
    }
  }
}
