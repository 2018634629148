.s2wrap {
  width: 100%;
  background-color: #e5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .s2container {
    width: 90%;
    background-color: inherit;
    padding: 3vh 0px 1vh 0px !important;
    max-width: 1200px;
    @media (max-width: 900px) {
      width: 90%;
      overflow-y: hidden !important;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      width: 100%;
      padding: 3vh 5vw 3vh 5vw !important;
    }
    .boxcontainer {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: inherit;
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        flex-direction: column;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        flex-direction: column;
      }
      .boxImg {
        width: 50%;
        background-color: inherit;
        user-select: none;
        @media (max-width: 900px) {
          // Material-UI's breakpoint for 'xs'
          width: 100%;
        }
        @media (max-width: 600px) {
          // Material-UI's breakpoint for 'xs'
          width: 100%;
        }
      }
      .boxInfo {
        width: 50%;
        background-color: inherit;
        padding: 3vh 5vw 3vh 5vw !important;
        @media (max-width: 900px) {
          // Material-UI's breakpoint for 'xs'
          padding: 3vh 2vw 3vh 2vw !important;
          width: 100%;
        }
        @media (max-width: 600px) {
          // Material-UI's breakpoint for 'xs'
          padding: 3vh 2vw 3vh 2vw !important;
          width: 100%;
        }
        .list {
          .item {
            padding: 1vh 0 1vh 0;
            .text {
              text-align: justify;
              text-justify: inter-ideograph;
              word-wrap: break-word;
              word-break: break-all;
              //text-justify:inter-ideograph;
              hyphens: auto;
              font-size: 1.6rem !important;
              margin-left: 0.5vw;
              letter-spacing: 0.02em; // 增加字母間距
              @media (max-width: 900px) {
                // Material-UI's breakpoint for 'xs'
                font-size: 1.6rem !important;
                margin-left: 1vw;
              }
              @media (max-width: 600px) {
                // Material-UI's breakpoint for 'xs'
                font-size: 1.4rem !important;
                margin-left: 2vw;
              }
            }
          }
          .h3 {
            font-size: 2.4rem;
            font-weight: bold;
            margin-bottom: 1vh;
            text-align: left;
            .span {
              display: inline-block; // 確保底線只在文字下方
              border-bottom: 2px solid #05736f;
            }
            @media (max-width: 900px) {
              // Material-UI's breakpoint for 'xs'
              font-size: 2.4rem;
            }
            @media (max-width: 600px) {
              // Material-UI's breakpoint for 'xs'
              font-size: 1.8rem;
              font-weight: bold;
              margin: 1vh 0;
            }
          }
        }
      }
    }
  }
}
