.root {
  user-select: none;
  max-width: 300px !important;
  text-align: center;
  gap: 1vw !important;
  padding-top: 30px !important;
  border-radius: 20px !important;
  overflow: hidden;
  background-color: #eef1f1 !important;
  @media (max-width: 900px) {
    // Material-UI's breakpoint for 'xs'
    max-width: none;
    width: 90%;
  }
  @media (max-width: 600px) {
    // Material-UI's breakpoint for 'xs'
    max-width: none;
    width: 80%;
  }
  padding-top: 1vh;
  .img_container {
    height: 60px;
    background-repeat: no-repeat; /* Prevents the background image from repeating */
    background-size: 60px 60px; /* Adjusts the size of the background image */
    background-position: center;
  }
  .title_container {
    background-color: #eef1f1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: -10px; // Position the triangle just outside the bottom of the title container
      left: 50%; // Center the triangle
      transform: translateX(-50%); // Ensure the centering is accurate
      width: 0;
      height: 0;
      border-left: 15px solid transparent; // Left side of the triangle
      border-right: 15px solid transparent; // Right side of the triangle
      border-top: 15px solid #eef1f1; // Top side of the triangle matching the title container's background.
    }
    .title {
      font-size: 2.4rem * 0.8;
      font-weight: 700;
    }
  }
  .content_container {
    padding: 20px;
    //background-color: #05736f;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      color: #fff;
      font-size: 2rem * 0.8;
      text-align: justify;
      word-break: break-all;
      font-weight: lighter;
    }
  }
}
