.wrap {
  position: relative;
  margin: 2vh 0px 2vh 0px;
  border-radius: 20px !important;
  max-height: 720px;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    max-height: none;
    height: auto;
    flex-direction: column !important;
    min-height: none;
  }
  @media (max-width: 600px) {
    // Material-UI's breakpoint for 'xs'
    height: auto;
    max-height: none;
    min-height: none;
    flex-direction: column;
  }
  .boxImg {
    width: 50%;
    min-height: 480px;
    background-size: cover;
    background-repeat: no-repeat !important; /* Do not repeat the image */
    background-position: center;
    @media (max-width: 900px) {
      width: 100%;
    }
    @media (max-width: 600px) {
      width: 100%;
      min-height: 350px;
    }
  }

  .boxInfo {
    background-color: #eef1f1;
    width: 50%;
    min-height: 480px;
    padding: 20px 5vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 900px) {
      padding: 4vh 8vw 4vh 8vw;
      min-height: none;
      flex-direction: column;
      width: 100%;
      height: auto !important;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      padding: 6vh 5vw 6vh 5vw;
      min-height: none;
      width: 100%;
      min-height: none;
      flex-direction: column;
      height: auto !important;
    }
    .slogan {
      text-align: left;
      font-style: italic;
      font-size: 2rem;
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 2rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 2rem;
      }
    }
    .title {
      font-size: 3.2rem;
      margin-bottom: 10px;
      font-weight: 500;
      color: black;
      text-align: left;
      font-weight: bold;
      line-height: 1.6;
      margin: 3vh 0px;
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 2.4rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 2.4rem;
      }
    }
    .content {
      text-align: left;
      line-height: 10px !important;
      font-size: 2rem;
      line-height: 1.5 !important; // 增加行高以改善閱讀性
      letter-spacing: 0.02em; // 增加字母間距
      color: #000000;

      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        line-height: 2 !important; // 增加行高以改善閱讀性
        font-size: 1.8rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.5rem;
      }
    }
  }
}
