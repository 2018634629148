.grid_info {
  background-color: #eef1f1;
  .wrap_info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    @media (max-width: 900px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 2.2rem;
      padding: 30px;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      font-size: 1.8rem;
    }
    .h1 {
      text-align: left;
      font-size: 2.4rem * 0.8;
      font-weight: 700;
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 2.2rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.8rem;
      }
    }
    .p {
      text-align: left;
      margin-top: 30px;
      font-size: 2rem * 0.8;
      line-height: 1.6;
      letter-spacing: 2;
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.8rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.6rem;
      }
    }
  }
}

.grid_img {
  width: 100%;
  user-select: none;
  //background-color: #e3edf5;
  padding: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
