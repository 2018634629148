.wrap {
  margin: 2vh 0px 2vh 0px;
  border-radius: 20px !important;
  height: 56vh;
  min-height: 450px;
  max-height: 720px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eef1f1 !important;
  @media (max-width: 900px) {
    height: auto;
    flex-direction: column;
    min-height: none;
  }
  @media (max-width: 600px) {
    // Material-UI's breakpoint for 'xs'
    height: auto;
    min-height: none;
    flex-direction: column;
  }
  .boxImg {
    position: relative;
    width: 50%;
    height: 100%;
    /* background-size: cover;
    background-repeat: no-repeat !important; /* Do not repeat the image 
    background-position: center;*/
    overflow: hidden; /* Ensure content inside doesn't overflow */
    @media (max-width: 900px) {
      display: none;
    }
    @media (max-width: 600px) {
      display: none;
    }
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover; /* Or contain, depending on your requirement */
      display: block;
      background-color: #fff;
    }
  }
  .boxInfo {
    position: relative;
    background-color: #eef1f1;
    width: 50%;
    height: 100%;
    padding: 5vh 5vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 900px) {
      padding: 4vh 8vw 4vh 8vw;
      flex-direction: column;
      width: 100%;
    }
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      padding: 6vh 5vw 6vh 5vw;
      width: 100%;
      flex-direction: column;
    }
    .slogan {
      text-align: left;
      font-style: italic;
      font-size: 2rem * 0.8;
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 2rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 2rem;
      }
    }
    .title {
      font-size: 3.5rem * 0.8;
      margin-bottom: 10px;
      font-weight: 700;
      color: black;
      text-align: left;
      margin: 3vh 0px;
    }
    .content {
      text-align: left;
      line-height: 10px !important;
      font-size: 2.2rem * 0.8;
      line-height: 1.5 !important; // 增加行高以改善閱讀性
      letter-spacing: 0.02em; // 增加字母間距
      color: #000000;

      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 2rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.8rem;
      }
    }
    .btn {
      font-size: 2rem * 0.8;
      background-color: #4074b0;
      border-radius: 40px;
      padding: 20px 30px !important;
      border-color: #4074b0 !important;
      color: white;
      margin-top: 3vh;
      width: 60%;
      height: 3vh;
      transition: background-color 0.3s ease;
      align-self: center;
      @media (max-width: 900px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.8rem;
      }
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        font-size: 1.8rem !important;
      }
      &:hover {
        background-color: white;
        color: #4074b0;
      }
    }
  }
}
