// NavigationBar.scss
.whiteToolbar {
  color: #333; // 设置工具栏文字和图标为深灰色，提高对比度
  background-color: white; // 设置背景为白色
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); // 添加灰色阴影
}

.navLink {
  text-transform: capitalize !important;
  padding: 0px 15px !important;
  font-size: 14px !important;
  margin-right: 15px !important;
  font-weight: 500 !important;
  color: black;
  text-decoration: none; // 去掉默认的下划线
  position: relative; // 设置定位上下文，为 ::after 伪元素定位提供基准
  overflow: hidden; // 防止伪元素溢出链接的边界
  transition: all 0.3s ease; // 添加平滑过渡效果

  &:hover {
    background-color: white !important;
    transform: translateY(2px); // 将文本向下移动 2 像素
  }

  &::after {
    content: ''; // 必须有内容属性，即使没有内容
    position: absolute; // 绝对定位，相对于 navLink
    bottom: 0; // 底部对齐
    left: 0; // 从左侧开始
    width: 0; // 默认宽度为 0
    height: 1.5px; // 底线的厚度
    background-color: #1ea09c; // 底线的颜色
    transition: width 0.3s ease; // 只对宽度变化应用过渡效果
  }

  &:hover::after {
    width: 100%; // 在悬停时将宽度设置为 100%
  }
}

.contactButton {
  text-transform: capitalize !important;
  padding: 3px 15px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #1ea09c !important; // 初始文字颜色
  background-color: white; // 初始背景颜色
  border: 1.2px solid #1ea09c !important; // 边框设定
  border-radius: 20px !important; // 圆角边框
  outline: none; // 移除焦点时的轮廓线
  cursor: pointer; // 鼠标样式
  margin-right: 10px !important;
  margin-left: 30px !important;
  transition: background-color 0.3s ease, color 0.3s ease; // 过渡效果设置

  &:hover {
    background-color: #1ea09c !important; // 悬停时的背景颜色
    font-weight: 800 !important;
    color: white !important; // 悬停时的文字颜色
  }
}

.logoLink {
  display: inline-flex;
  align-items: center;
  position: relative; // 设置相对定位以为伪元素提供定位上下文

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5); // 白色半透明覆盖层
    opacity: 0; // 默认不显示
    transition: opacity 0.3s ease; // 平滑过渡效果
    pointer-events: none;
  }

  &:hover::after {
    opacity: 1; // 鼠标悬停时显示覆盖层
  }
}

.s2root {
  background-color: #eef1f1 !important;
  padding-top: 2vh;
  padding-bottom: 7vh;
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 100% !important;
  max-width: none !important;
  .s2title {
    color: #05736f;
    text-align: center;
    font-size: 2.4rem !important;
    font-weight: bold;
    margin: 5vh 0;
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs'
      margin: 7vh 0;
    }
  }
  .s2gridcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }
  .s2grid {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1vh;
    margin-bottom: 1vh;
    @media (max-width: 600px) {
      // Material-UI's breakpoint for 'xs
      margin-top: 1vh;
      margin-bottom: 1vh;
    }
  }
}

.s3root {
  /* background-color: #e5f5f5 !important; */
  width: 100% !important;
  max-width: none !important;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat !important; /* Do not repeat the image */
  background-position: center;
  .s3overlay {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    padding: 30px 0px !important;
    min-width: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .s3gridcontainer {
      max-width: 1400px;
      height: 90%;
      width: 90%;
      background-color: #fff;
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        width: 95% !important;
      }
      .s3grid_content {
        padding: 40px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .h3 {
          text-align: left;
          font-size: 2.8rem;
          font-weight: bold;
          margin: 20px 0px;
          @media (max-width: 900px) {
            // Material-UI's breakpoint for 'xs'
            font-size: 2.8rem !important;
          }
          @media (max-width: 600px) {
            // Material-UI's breakpoint for 'xs'
            font-size: 2.4rem !important;
          }
        }
        .title_container {
          align-self: flex-start;
          display: flex;
          justify-content: center;
          align-items: center;
          .label {
            color: #1ea09c;
          }
          .h4 {
            align-self: flex-start;
            text-align: left;
            font-weight: bold;
            padding-left: 5px;
            color: #1ea09c;
            font-size: 2.4rem !important;
            @media (max-width: 900px) {
              // Material-UI's breakpoint for 'xs'
              font-size: 2.2rem !important;
            }
            @media (max-width: 600px) {
              // Material-UI's breakpoint for 'xs'
              font-size: 2rem !important;
            }
          }
        }

        .divider {
          border-color: #1ea09c;
          border-width: 1px;
          width: 10%;
          align-self: flex-start;
          margin: 5px 0px 10px 0px;
        }
        .p {
          align-self: flex-start;
          text-align: left;
          line-height: 1.5 !important;
          color: rgb(67, 65, 65);
          margin-bottom: 15px;
          font-size: 1.8rem !important;
          @media (max-width: 900px) {
            // Material-UI's breakpoint for 'xs'
            font-size: 1.4rem !important;
          }
          @media (max-width: 600px) {
            // Material-UI's breakpoint for 'xs'
            font-size: 1.4rem !important;
          }
        }
      }
      .s3grid_img {
        background-color: #edf4f4;
        display: flex;
        align-items: center;
        justify-content: center;
        .img {
          width: 100%;
          height: 100%;
          object-fit: cover; /* Or contain, depending on your requirement */
          display: block;
        }
      }
    }
  }
}

.s4root {
  background-color: #e5f5f5 !important;
  width: 100% !important;
  max-width: none !important;
  padding: 3vh 5vw !important;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .s4wrap {
    max-width: 1400px;
    .s4title {
      color: #05736f;
      margin: 5vh 0;
      font-size: 2.4rem !important;
      font-weight: bold;
      @media (max-width: 600px) {
        // Material-UI's breakpoint for 'xs'
        margin: 7vh 0;
      }
    }
    .s4btn {
      margin: 5vh auto;
      border-radius: 40px;
      padding: 1.5vh 7vw;
      font-size: 1.4rem;
      border: 2px solid black;
      color: black;
      font-weight: bold;
      background-color: white;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: black;
        color: white;
      }
    }
  }
}
