@import '../styles/variable.scss';
@import '../styles/mixin.scss';
@import '../styles/extend.scss';
@import '../styles/keyframe.scss';

.btn {
  @extend %alinkBtn;
  position: fixed;
  bottom: 2.4rem;
  right: 2.4rem;
  width: 8rem;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.8;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #1ec1bc;
  flex-direction: column;
  text-transform: uppercase;
  color: #fff;

  @include media-breakpoint-down(sm) {
    width: 6rem;
    height: 6rem;
  }

  /* &::before {
    display: block;
    width: 3.8rem;
    height: 1.6rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 0.8rem;
    content: '';
  }
 */
  &:hover {
    opacity: 1;
  }
}
